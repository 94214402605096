export const welcomeHeaders = {
  line1: "Welcome to",
  line2: "Kindergym",
  line3: "Indonesia"
}
export const aboutUs = {
  whyKindergym: "Why Kindergym",
  whyStartEarly: "Why Start Early"
}
export const getToKnowUsBetter = {
  getToKnowUsBetter: "Get to Know Us Better",
  ourFascilities: "Our Facilities",
  ourTeachers: "Our Teachers"
}
export const ourCentres = {headerText: "Our Centres"}
export const ourProgrammes = {headerText: "Our Programmes"}
export const contactUs = {headerText: "Contact Us"}