import styles from "./App.module.css";
import SignUp from "./authentication/signup";
import Home from "./home/home";
import ParentsDashboard from "./dashboard/dashboard";
import Programmes from "./programdetails/programmes";
import ChooseaCentre from "./registration/chooseacentre";
import Franchise from "./franchise/franchise";
import {Routes, Route} from "react-router-dom";

function App() {

const cssProps = {
	bgDark: styles.bgDark,
	bgGreen: styles.bgGreen,
	centerHorizontally: styles.centerHorizontally,
	centerVertically: styles.centerVertically,
	fitContainer: styles.fitContainer,
	flexHorizontally: styles.flexHorizontally,
	flexGap: styles.flexGap,
	flexVertically: styles.flexVertically,
	theGrid: styles.theGrid,
	maxWidthMinHeight: styles.maxWidthMinHeight
}

  return (
    <>
      <Routes>
        <Route path="/" element={<Home {...cssProps}/>}/>
        <Route path="/chooseacentre" element={<ChooseaCentre {...cssProps}/>}/>
        <Route path="/dashboard" element={<ParentsDashboard {...cssProps}/>}/>
        <Route path="/signup" element={<SignUp {...cssProps}/>}/>
        <Route path="/franchise" element={<Franchise {...cssProps}/>}/>
        <Route path="/programme/:whichClass" element={<Programmes {...cssProps}/>}/>
      </Routes>
    </>
  );
}

export default App;
