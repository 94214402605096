import {descriptionTexts} from "../descriptions/descriptions";
import topleft from "../imgs/IMG_1278.webp";
import btnimgartclass from "../imgs/btnimgartclass.jpg";
import btnimgmusicclass from "../imgs/btnimgmusicclass.jpg";

export const programmesContent = [
  {cardHeader: "Play Class",
   cardDetails: descriptionTexts.playClassCardDetails,
   cardimg: topleft,
   cardimgalt: "Play Class",
   cardpointer: "playclass"},
  {cardHeader: "Art Class",
   cardDetails: descriptionTexts.artClassCardDetails,
   cardimg: btnimgartclass,
   cardimgalt: "Art Class",
   cardpointer: "artclass"},
  {cardHeader: "Music Class",
   cardDetails: descriptionTexts.musicClassCardDetails,
   cardimg: btnimgmusicclass,
   cardimgalt: "Music Class",
   cardpointer: "musicclass"},
  {cardHeader: "Smart Class",
   cardDetails: descriptionTexts.smartClassCardDetails,
   cardimg: topleft,
   cardimgalt: "Smart Class",
   cardpointer: "smartclass"},
  {cardHeader: "Holiday Programme",
   cardDetails: descriptionTexts.holidayClassCardDetails,
   cardimg: topleft,
   cardimgalt: "Holiday Programme",
   cardpointer: "holidayprogramme"}] 
