import React from "react";
import Button from "@mui/material/Button";
import {useNavigate} from "react-router-dom";
import {styled} from "@mui/material/styles";
import {brown, creme, darkGreen, darkGrey, btnGreen, 
        btnOrange, btnLightGreen, btnMurkyGreen, btnBrown} from "../styling/colors"
import "@fontsource/dosis/700.css";

const CustomButton = styled(Button)({
  boxShadow: "none",
  textTransform: "none",
  fontSize: 16,
  padding: "6px 12px",
  border: "2px solid",
  lineHeight: 1.5,
  backgroundColor: btnOrange,
  borderColor: darkGreen,
  fontFamily: [
    "Dosis",
    "-apple-system",
    "BlinkMacSystemFont",
    "Roboto",
    "Arial",
    "sans-serif",
  ].join(","),
  "&:hover": {
    backgroundColor: btnLightGreen,
    border: "2px solid",
    borderColor: brown,
    boxShadow: "none",
  },
  "&:active": {
    boxShadow: "none",
    backgroundColor: btnMurkyGreen,
    borderColor: btnBrown,
  },
  "&:focus": {boxShadow: "0 0 0 0.2rem rgba(0,123,55,.5)"},
}); 

const ColorButton = styled(Button)(({theme}) => ({
  color: darkGrey,
  backgroundColor: darkGrey,
  "&:hover": {
    backgroundColor: "black",
  }}));

export function DarkGreenButton(props) {
  return ( 
    <CustomButton 
      variant="outlined"
      name={props.name} 
      sx={{color: btnGreen}} 
      onClick={props.onClick}>
        {props.label}
    </CustomButton>
  )}

export function DarkGreyButton(props) {
  return (
    <ColorButton 
      variant="contained" 
      name={props.name}
      sx={{color: creme}} 
      onClick={props.onClick}>
        {props.label}
    </ColorButton>
  )}

export function BackButton(props) {

let navigate = useNavigate();

  return ( 
    <CustomButton 
      variant="outlined"
      sx={{color: btnGreen, marginLeft: "1rem"}} 
      onClick={() => navigate("/") }>
        BACK
    </CustomButton>
  )}