import React from "react";
import {DarkGreenButton} from "../shared/custombuttons";
import {centerThingsWidthHeight, flexHorizontally} from "../styling/cssclasses";

export default function ChooseaCentre(props) {

const handleClick = e => window.location.replace(e.target.name);

  return (
    <div className={centerThingsWidthHeight}> 
      <h3>PLEASE CHOOSE A CENTRE</h3>
      <div className={flexHorizontally}>
        <DarkGreenButton
          label="CIREBON"
          name="https://form.jotform.com/222761007997463" 
          onClick={e => handleClick(e)}/>
        <DarkGreenButton 
          label="JAMBI"
          name="https://form.jotform.com/222761972339464"
          onClick={e => handleClick(e)}/>
      </div>
    </div>
  )
}
