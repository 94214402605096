import React, {useEffect, useState} from "react";
import CircularProgress from "@mui/material/CircularProgress";
import {useParams} from "react-router-dom";
import {firebaseStorage} from "../firebase";
import {ref, getDownloadURL} from "firebase/storage";
import {centerThingsWidthHeight} from "../styling/cssclasses";

export default function Programmes(props) {
 
  let {whichClass} = useParams();
  const [imgUrl, setImgUrl] = useState(<CircularProgress/>);

  useEffect(() => {
    getDownloadURL(ref(firebaseStorage, `global/${whichClass}.jpg`))
    .then(url => setImgUrl(url))
  }, [whichClass])

  return (
    <div className={centerThingsWidthHeight}>
      {imgUrl? <img src={imgUrl} altText="not available"style={{width: "100vw"}}/> : <CircularProgress/>}
    </div>
  )
}
