import React from "react";
import TextField from "@mui/material/TextField";
import {Controller} from "react-hook-form";

export default function FormField(props) {
  return (
    <Controller 
      name={props.name}
      control={props.control}
      render={( {field: {onChange, value}} ) => 
        <TextField
          label={props.label}
          type={props.type}
          placeholder={props.placeholder}
          value={value} 
          onChange={onChange}
        />}
    />
  )
}
