import React from "react";
import CopyrightIcon from "@mui/icons-material/Copyright";
import {creme} from "../styling/colors";

const style={
  display: "flex",
  flexDirection: "row",
  justifyContent: "center"
}

export default function Footer(props) {
  return (
    <footer>
      <div style={style}>
        <CopyrightIcon sx={{color: creme}} id="copyrightIcon"/> &nbsp; 
        <p>2017 KINDERGYM INDONESIA</p>
      </div>
      <p>All Rights Reserved</p>
    </footer>
  )
}
