import React, {useContext} from "react";
import Button from "@mui/material/Button";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import Divider from "@mui/material/Divider";
import ListItemButton from "@mui/material/ListItemButton";
import kindergymlogo from "../imgs/kindergymlogo.png";
import MenuRoundedIcon from '@mui/icons-material/MenuRounded';
import {useNavigate, Link} from "react-router-dom";
import {AuthContext} from "../contexts/authcontexts";
import {RefContext} from "../contexts/refcontext";
import {DrawerContext} from "../contexts/drawer";
import {lightgreen} from "../styling/colors";
import {BackButton} from "../shared/custombuttons";

export default function Navbar(props) {

//init useNavigate for routing
let navigate = useNavigate();

let currentUrl = () => window.location.href;

//check if there's a user online
const {isOnline, handleSignOut} = useContext(AuthContext);
const {openDrawer, setOpenDrawer} = useContext(DrawerContext);

const drawerStyle = {
  height: "75vh",
  borderBottomRightRadius: "5rem", 
  backgroundColor: lightgreen
}

//ref to different divs from /home
const {aboutUsRef, ourCentresRef, programmeRef, contactUsRef} = useContext(RefContext);

//navigate elsewhere after a button has been clicked
function scrollTo(elemRef){
  elemRef.current.scrollIntoView({block: "center", behavior: "smooth"});
  setTimeout(() => setOpenDrawer(false), 500);
}

const handleRegister = () => {navigate("/chooseacentre"); setOpenDrawer(false)};
const handleAboutUs = () => scrollTo(aboutUsRef);
const handleOurCentres = () => scrollTo(ourCentresRef);
const handleProgrammes = () => scrollTo(programmeRef);
const handleContactUs = () => scrollTo(contactUsRef);
const handleFranchise = () => {navigate("/franchise"); setOpenDrawer(false)};

const menus = [
  {label: "ABOUT US", handler: handleAboutUs}, 
  {label: "OUR CENTRES", handler: handleOurCentres},
  {label: "PROGRAMMES", handler: handleProgrammes},
  {label: "REGISTER", handler: handleRegister},
  {label: "CONTACT US", handler: handleContactUs},
  {label: "INFO FRANCHISE", handler: handleFranchise}]

return (
  <nav>
    {currentUrl().substring(22).length?
    <BackButton/> :
    <Button onClick={() => setOpenDrawer(!openDrawer)}>
      <MenuRoundedIcon id="menuIcon"/>
    </Button>}
    <SwipeableDrawer
      open={openDrawer}
      PaperProps={{style: drawerStyle}}
      onClose={() => setOpenDrawer(false)}
      onOpen={() => setOpenDrawer(true)}>
        <div style={{height: "12vh"}}/>
        <div id="drawerMenu">
          {menus.map(menu => (
            <ListItemButton key={menu.handler} onClick={menu.handler}>
              <h3>{menu.label}</h3>
            </ListItemButton>
          ))}
          <Divider sx={{marginTop: "1rem", marginBottom: "1rem"}}/>
          {isOnline?
            <>
              <ListItemButton onClick={() => {navigate("/dashboard"); setOpenDrawer(false);}}>
                <h3>MY DASHBOARD</h3>
              </ListItemButton>
              <ListItemButton onClick={handleSignOut}>
                <h3>SIGN OUT</h3>
              </ListItemButton>
            </> :
            <ListItemButton onClick={() => {navigate("/dashboard"); setOpenDrawer(false)}}>
              <h3>SIGN IN</h3>
            </ListItemButton>}
        </div>
    </SwipeableDrawer>
    <div>
      <Link to={"/"}>
        <img src={kindergymlogo} alt="kindergym logo" id="navbarKindergymLogo"/>
      </Link>
    </div>
  </nav>
)}
