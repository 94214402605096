import React, {useContext} from "react";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import {AuthContext} from "../contexts/authcontexts";
import {centerFlexVertical} from "../styling/cssclasses";

export default function SignIn() {

const {username, password, handleSignIn} = useContext(AuthContext);

return (
  <div id="signin" className={centerFlexVertical}>
    <TextField inputRef={username} error variant="outlined" label="Silakan isi username"/>
    <TextField inputRef={password} error variant="outlined" label="password" type="password"/>
    <Button variant="contained" onClick={handleSignIn}><h2>SIGN IN</h2></Button>
  </div>
  )
}
