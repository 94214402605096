import React, {createContext, useState} from "react";

export const DrawerContext = createContext();

const DrawerContextProvider = (props) => {
 
  //controls the open and close state of navbar drawer element
  const [openDrawer, setOpenDrawer] = useState(false);

  return (
    <DrawerContext.Provider 
      value={{openDrawer, setOpenDrawer}}>
        {props.children}
    </DrawerContext.Provider>
  )
}

export default DrawerContextProvider;