import styles from "../App.module.css";

export const centerThingsWidthHeight = `${styles.maxWidthMinHeight} ${styles.centerVertically} ${styles.centerHorizontally} ${styles.flexVertically}`
export const flexHorizontally = `${styles.flexHorizontally} ${styles.flexGap}`
export const centerThings = `${styles.centerVertically} ${styles.centerHorizontally}`
export const greenBgGrid = `${styles.theGrid} ${styles.bgGreen}`
export const centerFlexVertical = `${styles.flexVertically} ${styles.centerHorizontally}`
export const centerFlexHorizontal = `${styles.flexHorizontally} ${styles.centerHorizontally}`
export const dark = `${styles.darkBox} ${styles.bgDark} ${styles.centerVertically} ${styles.centerHorizontally} ${styles.fitContainer}`
export const programmeCardsStyles = `${styles.flexVertically} ${styles.centerHorizontally} ${styles.flexGap}`
