import React from "react";
import Button from "@mui/material/Button";
import FormField from "../shared/formfield";
import {createUserWithEmailAndPassword} from "firebase/auth";
import {firebaseAuth} from "../firebase";
import {useForm} from "react-hook-form";
import {useNavigate} from "react-router-dom";

export default function SignUp(props) {

  const {control, handleSubmit} = useForm();
  
  let navigate = useNavigate();

  const onSubmit = (data) => {
    createUserWithEmailAndPassword(
      firebaseAuth, 
      `${data.username}@kindergym.id`, 
      data.password
      ).then((userCred) => {
        const user = userCred.user;
        console.log(user); 
      }).catch((err) => {
        const errCode = err.code;
        const errMsg = err.message;
        console.log(errCode, errMsg)
      })
      navigate("/profile")
    }

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <FormField 
          name="username"
          control={control}
          label="Username"
          placeholder="Username" 
          />
        <div style={{ display: "flex", flexDirection: "row" }}>
          <FormField 
            name="password"
            control={control}
            label="Password"
            placeholder="type your password here" 
            />
          <FormField 
            name="validatepassword"
            control={control}
            label="Retype Password"
            placeholder="please retype your password" 
            />
        </div>
        <Button variant="contained" type="submit">
          SIGN UP
        </Button>
      </form>
    </div>
  )
}
