export const bgGreen = "#87CEC6";
export const brown = "#CC704B";
export const creme = "#FBF2CF";
export const darkGreen = "#1C6758";
export const darkGrey = "#2C3639";
export const grey = "#2C3333";
export const lightgreen = "#F1F7E7";
export const btnGreen = "#064420";
export const btnOrange = "#EBA83A";
export const btnLightGreen = "E4E9BE";
export const btnMurkyGreen = "#A09F57";
export const btnBrown = "#9D5C0D";