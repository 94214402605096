import React from "react";
import cloud1 from "../imgs/cloud1.svg";
import cloud2 from "../imgs/cloud2.svg";
import cloud3 from "../imgs/cloud3.svg";
import {motion} from "framer-motion";

export default function CloudStagger(props) {

const clouds = [cloud3, cloud2, cloud1];

  return (
    <>
      {clouds.map((cloud, i) => 
        <motion.img
          key={cloud}
          id={`cloud${i}`}
          src={cloud} 
          alt={`cloud${i}`} 
          animate={{x: [0, (3*(i+1)), 0], y: [-2*i, 0, -2*i]}}
          transition={{duration: 5*(i+1), delay: i*3, repeat: Infinity}}
          /> 
      )}
    </>
  )
}
