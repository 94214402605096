import React, {createContext, useState, useEffect, useRef} from "react";
import {useNavigate} from "react-router-dom";
import {firebaseAuth} from "../firebase";
import {onAuthStateChanged, signOut, signInWithEmailAndPassword} from "firebase/auth";

export const AuthContext = createContext();

const AuthContextProvider = (props) => {
 
  //keep the status of a user whether he's online or not
  const [isOnline, setIsOnline] = useState(null);

  //get username and password from the signup element
  const username = useRef();
  const password = useRef();

  let navigate = useNavigate();

  useEffect(() => { 
    onAuthStateChanged(firebaseAuth, (user) => {
      if (user) {setIsOnline(user.uid)}else{
        console.log("Tidak ada user yang sedang aktif")}})}, [])

  const handleSignIn = () => 
    signInWithEmailAndPassword(firebaseAuth, `${username.current.value}@kindergym.id`, password.current.value)
    .then(() => {navigate("/dashboard")})
    .catch(err => {
      const errorCode = err.code;
      const errorMessage = err.message;
      alert( errorCode + errorMessage);
    }); 
  
  const handleSignOut = () => 
    signOut(firebaseAuth).then(() => {
      console.log("sign out successful");
      setIsOnline(null);
      navigate("/")}).catch(error => {console.log(error);});
  
  return(
    <AuthContext.Provider 
      value={{isOnline, setIsOnline,
              username, password,  
              handleSignOut, handleSignIn}}>
      {props.children} 
    </AuthContext.Provider>
  );
}

export default AuthContextProvider;