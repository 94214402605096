import React, {useContext, useReducer, useEffect} from "react";
import Modal from "@mui/material/Modal";
import Kindo from "../shared/kindo";
import kindopng from "../imgs/kindo.png";
import Grass from "../shared/grass";
import CloudStagger from "../shared/cloudstagger";
import KindergymBeeAnim from "../shared/kindergymbeeanim";
import RainbowAndCloud from "../shared/rainbowandcloud";
import ProgrammeCards from "./programmecards";
import ContactUsForm from "./contactusform";
import Addresses from "./addresses";
import topleft from "../imgs/IMG_1278.webp";
import bottomleft from "../imgs/IMG_1283.webp";
import topright from "../imgs/IMG_9404.webp";
import bottomright from "../imgs/IMG_1281.webp";
import whywaitimg from "../imgs/whywait.png"
import CircularProgress from "@mui/material/CircularProgress";
import Button from "@mui/material/Button";
import {ref, getDownloadURL} from "firebase/storage";
import {getDoc, doc} from "firebase/firestore";
import {firebaseStorage, firestoreDB} from "../firebase";
import {RefContext} from "../contexts/refcontext";
import {centreAddresses} from "./centreaddresses";
import {TextDescription, TextHeader, TextSubHeader} from "../shared/textbox";
import {descriptionTexts} from "../descriptions/descriptions";
import {welcomeHeaders, aboutUs, getToKnowUsBetter, 
        ourCentres, ourProgrammes, contactUs} from "../descriptions/headers";
import {centerThings, greenBgGrid, centerFlexHorizontal,
        centerFlexVertical, dark, programmeCardsStyles} from "../styling/cssclasses"; 
import {DarkGreenButton} from "../shared/custombuttons";
import {useNavigate} from "react-router-dom";
import {programmesContent} from "./programmecardscontent";
import {motion} from "framer-motion";
import "@fontsource/pacifico";

const imgInBtn = {
  height: "100%",
  maxWidth: "100vw",
  padding: 0,
  margin: 0,
}

const initialState = {
  isOpen: false,
  filename: "",
  url: "",
}

export default function Home(props) {

//used as reference to different elements
const {aboutUsRef, ourCentresRef, programmeRef, contactUsRef, welcomeRef} = useContext(RefContext);

const [state, dispatch] = useReducer(reducer, initialState);

function reducer(state, action){
  switch(action.type) {
    case "isOpen": return {...state, isOpen: action.payload}
    case "filename": return {...state, filename: action.payload} 
    case "url": return {...state, url: action.payload} 
}}

let navigate = useNavigate();

useEffect(() => {
  async function isModalShown(){
    const docSnap = await getDoc(doc(firestoreDB, "globalinfo", "ismodalshown"))
    if(docSnap.exists()){
      dispatch({type: "isOpen", payload: docSnap.data().value})
      dispatch({type: "filename", payload: docSnap.data().filename})
    }else{console.log("the status of ismodalshown not found")}} 
  isModalShown(); 
}, [])

useEffect(() => {
  getDownloadURL(ref(firebaseStorage, `global/${state.filename}`))
  .then(url => dispatch({type: "url", payload: url}))
  .catch(err => console.log(err))
}, [state.filename])

  return (
    <>
      {/* <Modal open={state.isOpen} onClose={() => dispatch({type: "isOpen", payload: false})}>
        {(state.isOpen === false)? <CircularProgress/> :
          <>{state.url? 
            <div id="poster">
              <Button sx={imgInBtn}onClick={() => dispatch({type: "isOpen", payload: false})}>
                <img src={state.url} alt="Holiday Class Dec 2022"/>
              </Button>
            </div> : ""}
          </>}
      </Modal> */}
      <div id="welcome" ref={welcomeRef} className={greenBgGrid}>
        <div id="welcomeText">
          <div className={centerFlexVertical}>
            <TextHeader header={welcomeHeaders.line1}/>
            <TextHeader header={welcomeHeaders.line2}/>
            <TextHeader header={welcomeHeaders.line3}/>
            <TextDescription description={descriptionTexts.welcome}/>
          </div>
        </div>
        <div id="kindoAnim" className={props.centerHorizontally}><Kindo/></div>
        <div id="welcomeCloud"><CloudStagger/></div>
        <div id="kindergymBeeAnim" className={centerThings}><KindergymBeeAnim/></div>
        <div id="welcomeGrass"><Grass/></div>
      </div>
      <div id="whyKindergym" ref={aboutUsRef} className={props.theGrid}>
        <div id="whyKindergymImg" className={centerThings}>
          <img src={whywaitimg} alt="why wait"/>
        </div>
        <div id="whyKindergymTexts" className={props.flexVertically}>
          <TextHeader header={aboutUs.whyKindergym}/>
          <TextDescription description={descriptionTexts.whyKindergymp1}/>
          <TextDescription description={descriptionTexts.whyKindergymp2}/>
          <TextDescription description={descriptionTexts.whyKindergymp3}/>
        </div>
      </div>
      <div id="whyStartEarly" className={greenBgGrid}>
        <div id="whyStartEarlyTexts" className={centerFlexVertical}>
          <TextHeader header={aboutUs.whyStartEarly}/>
          <TextDescription description={descriptionTexts.whyStartEarlyl1}/>
          <TextDescription description={descriptionTexts.whyStartEarlyl2}/>
          <TextDescription description={descriptionTexts.whyStartEarlyl3}/>
          <TextDescription description={descriptionTexts.whyStartEarlyl4}/>
          <TextDescription description={descriptionTexts.whyStartEarlyl5}/>
          <br/>
          <DarkGreenButton label="REGISTER NOW" onClick={() => navigate("/chooseacentre") }/>
        </div>
        <div id="rainbowAndClouds" className={centerThings}><RainbowAndCloud/></div>
        <div id="grass"><Grass/></div>
      </div>
      <div id="ourFacilities" className={props.theGrid}>
        <div id="ourFacilitiesTextsHeader"><TextHeader header={getToKnowUsBetter.getToKnowUsBetter}/></div>
        <div id="ourFacilitiesImg" className={centerThings}><img src={topright} alt="facilities"/></div>
        <div id="ourFacilitiesTexts"> 
          <TextSubHeader subheader={getToKnowUsBetter.ourFascilities}/>
          <TextDescription description={descriptionTexts.ourFascilitiesl1}/>
          <TextDescription description={descriptionTexts.ourFascilitiesl2}/>
        </div>
      </div>
      <div id="ourTeachers" className={greenBgGrid}>
        <div id="ourTeachersTexts" className={centerFlexVertical}>
          <TextSubHeader subheader={getToKnowUsBetter.ourTeachers}/>
          <TextDescription description={descriptionTexts.ourTeachersl1}/>
          <TextDescription description={descriptionTexts.ourTeachersl2}/>
        </div>
        <div id="ourTeachersKindo">
          <img src={kindopng} alt="Kindo"/>
        </div>
      </div>
      <div id="ourCentres" ref={ourCentresRef} className={props.theGrid}> 
        <div id="ourCentresHeader"><TextHeader header={ourCentres.headerText}/></div>
        <div id="ourCentresImgsCont">
          <div id="ourCentresImgs" className={props.theGrid}>
            <div id="topLeft" className={centerThings}>
              <img className={props.fitContainer} src={topleft} alt="img top left"/>
            </div>
            <div id="bottomCornerLeft" className={centerThings}>
              <img className={props.fitContainer} src={bottomleft} alt="img bottom left"/>
            </div>
            <div id="textBottomLeft" className={dark}><h1>JAMBI</h1></div>
            <div id="topMiddleRight" className={centerThings}>
              <img className={props.fitContainer} src={topright} alt="img top right"/>
            </div>
            <div id="textTopRight" className={dark}><h1>CIREBON</h1></div>
            <div id="bottomRight" className={centerThings}>
              <img className={props.fitContainer} src={bottomright} alt="img bottom right"/>
            </div>
          </div>
        </div>
      </div>
      <div id="programmes" ref={programmeRef} className={props.theGrid}>
        <div id="programmesHalfCircleCont">
          <div id="halfCircle"/>
          <TextHeader header={ourProgrammes.headerText}/>
        </div>
        <div id="programmeCards">
          {programmesContent.map((prog, i) => 
            <ProgrammeCards 
              key={prog.cardpointer} 
              cardHeader={prog.cardHeader}
              cardDetails={prog.cardDetails}
              cardimg={prog.cardimg}
              cardimgalt={prog.cardimgalt}
              cardpointer={prog.cardpointer}
              styles={programmeCardsStyles}/>)}
        </div>
      </div>
      <div id="contactUs" ref={contactUsRef} className={greenBgGrid}>
        <TextHeader header={contactUs.headerText}/>
        <div id="contactUsForm">
          <ContactUsForm centerVertically={props.centerVertically}/>
        </div>
        <div id="contactUsImg">
          <img src={topleft} imgalt="contact us"/> 
        </div>
      </div>
      <div id="addresses">
        <h3>Contact our nearest CENTRES to have a FREE TRIAL Class!</h3>
        <div className={centerFlexHorizontal}>
          {centreAddresses.map(addr => {return(
            <Addresses
              key={addr.phone} 
              city={addr.city}
              address={addr.address}
              phone={addr.phone}
              mobile={addr.mobile}/>)})} 
        </div>
      </div>
    </>
  )
}
