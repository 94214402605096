import React, {useRef, useEffect, useState} from "react";
import emailjs from "@emailjs/browser";
import {doc, getDoc} from "firebase/firestore";
import {firestoreDB} from "../firebase";

export default function ContactUsForm(props) {
 
const contactForm = useRef();

const [form, setForm] = useState({template: "", key: ""})

useEffect(() => {
  async function getForm(){
    const res = await getDoc(doc(firestoreDB, "globalinfo", "contactform"))
    if(res.exists()){setForm(res.data())}else{console.log("did not get the required fields; request to Firestore for EmailJS attr")}}
  getForm();
}, [])

const handleSubmit = e => {
  e.preventDefault();
  if(contactForm.current){
  emailjs.sendForm("kindergym_admin", form.template, contactForm.current, form.key)
  .then(res => console.log(res.text), err => console.log(err.text));
  contactForm.current.reset();
}}

  return (
    <div>
      <form onSubmit={handleSubmit} ref={contactForm}>
        <label>Your email</label>
        <input type="email" name="email" />
        <label>Write your message here</label>
        <textarea name="message" />
        <input type="submit" value="SEND" />
      </form> 
    </div>
  )
}
