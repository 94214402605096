import React from "react";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Button from "@mui/material/Button";
import {Link} from "react-router-dom";
import {centerThings} from "../styling/cssclasses";

export default function ProgrammeCards(props) {

  return (
    <Card className={props.styles}>
      <CardMedia component="img" image={props.cardimg} alt={props.cardimgalt}/>
      <CardContent>
        <h4>{props.cardHeader}</h4>
        <h5 variant="body2">{props.cardDetails}</h5>
      </CardContent>
      <CardActions id="cardactions">
        {/* <Button size="small">
          SHARE
        </Button> */}
        <Link to={`/programme/${props.cardpointer}`}>
          <Button size="small"><h6>LEARN MORE</h6></Button>
        </Link>
      </CardActions>
    </Card>
  )
}
