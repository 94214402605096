export const descriptionTexts = {
  welcome: "Where all the magical moments and the precious milestones of your loved one begin.",
  whyKindergymp1: "Kindergym classes are specially and carefully designed for an optimum early childhood development. We provide the best stimulation program for your loved ones since baby until preschool age.",
  whyKindergymp2: "Our journey began in November 2008, with now 3 centers located in Cirebon, West Java and Jambi, Sumatra.",
  whyKindergymp3: "Committed to always provide the best, high quality and continuous improvement on our programs and facilities, soon Kindergym will be to your proximity.",
  whyStartEarlyl1: "Stimulation should be given as early as possible, because the first 1000 days is the most important period in a child’s life for an optimum growth and cognitive development.",
  whyStartEarlyl2: "The first 12 months is known to be the most important period for a child’s brain development, as there are at least 700 synapses built per second.",
  whyStartEarlyl3: "A child’s brain builds up 80% of an adult’s cognitive ability by the time a child reaches 3 years old and the brain size triples by the time a child reaches 2 years old.",
  whyStartEarlyl4: "A child can also learn up to 900 words before he reaches 3 years old.",
  whyStartEarlyl5: "So, why wait, Mom and Dad?",
  ourFascilitiesl1: "Our location is strategically located close to your house and our equipments are safely designed and made. Not only very attractive and interesting to explore, but each of the equipments is manufactured with high quality and safety standard.",
  ourFascilitiesl2: "Because your child’s safety and comfort are our priority.",
  ourTeachersl1: "All of our teachers are fully trained and professional. They support you and your child to have a quality bonding time together, creating a safe space to explore, play and learn.",
  ourTeachersl2: "Our designed curriculum is based on the latest updated early education system, and is designed to grow with your child, from infant to preschool age.",
  playClassCardDetails: "0 to 4 years old",
  artClassCardDetails: "from 14 months old",
  musicClassCardDetails: "from 6 months old",
  smartClassCardDetails: "2 to 4 years old",
  holidayClassCardDetails: "our holiday programme",
}