export const centreAddresses = [{
  city: "CIREBON",
  address: "Jl. Pamitran No.33, KS Tubun, Cirebon",
  phone: "(0231) 880 1258",
  mobile: "0877 2975 1088",
}, {
  city: "JAMBI 1",
  address: "Jl. MH Thamrin No. 20, Jambi",
  phone: "(0741) 25197",
  mobile: "0811 744 6755"
}, {
  city: "JAMBI 2",
  address: "Jl. Kol. Amir Hamzah, Jambi", 
  phone: "(0741) 3622 520",
  mobile: "0813 1091 6755"
}] 
 