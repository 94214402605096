import React from "react";
import Button from "@mui/material/Button";
import {centerThingsWidthHeight} from "../styling/cssclasses";

export default function Franchise() {
  return (
    <div id="franchise" className={centerThingsWidthHeight}>
      <p>Interested to be our licensee and spread the learning fun in your city?</p>
      <p>Send your interest through WhatsApp</p> 
      <a href="https://wa.me/6281222599290" target="_blank"><h6>+62-812 2259 9290</h6></a>
      <p>Or write to our email</p>
      <a href="mailto: admin.pusat@kindergym.id" target="_blank"><h6>admin.pusat@kindergym.id</h6></a>
    </div>
  )
}
