import React, {useContext} from "react";
import SignIn from "../authentication/signin";
import UnderConstruction from "../shared/underconstruction";
import {AuthContext} from "../contexts/authcontexts";
import {centerThingsWidthHeight} from "../styling/cssclasses";

export default function ParentsDashboard(props) {
  
  const cssProps = {
    flexVertically: props.flexVertically,
    flexGapBetween: props.flexGapBetween
  }
  
  const {isOnline} = useContext(AuthContext); 

  return (
    <div className={centerThingsWidthHeight}> 
      {isOnline? <UnderConstruction/> : <SignIn/>}
    </div>
  )
}
