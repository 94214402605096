import React from "react";
import Navbar from "./navbar";
import Footer from "./footer";
import AuthContextProvider from "../contexts/authcontexts";
import RefContextProvider from "../contexts/refcontext";
import DrawerContextProvider from "../contexts/drawer";

export default function Layout({children}) {
  return (
    <>
      <AuthContextProvider>
        <RefContextProvider>
          <DrawerContextProvider>
            <Navbar/>
            {children}
            <Footer/>
          </DrawerContextProvider>
        </RefContextProvider>
      </AuthContextProvider>
    </>
  )
}
