import React from "react";

const styles = {
  display: "flex",
  flexDirection: "column",
}

export default function Addresses(props) {
  return (
    <div style={styles}>
      <p>{props.city}</p>
      <p>{props.address}</p>
      <p>Ph. {props.phone}</p>
      <p>Mobile {props.mobile}</p>
    </div>
  )
}
