import React, {useRef, createContext} from "react";

export const RefContext = createContext();

const RefContextProvider = (props) => {
  
  const welcomeRef = useRef();
  const aboutUsRef = useRef();
  const ourCentresRef = useRef();
  const programmeRef = useRef();
  const contactUsRef = useRef();
  
  return (
    <RefContext.Provider value={{welcomeRef, aboutUsRef, ourCentresRef, programmeRef, contactUsRef}}>
      {props.children}
    </RefContext.Provider>
  )
}

export default RefContextProvider;